/* istanbul ignore file */

const env = {};
// eslint-disable-next-line no-useless-escape
const locationRegexp = /^(?:(?:([\w\-]+)-dot-)?([\w\-]+)(?:\.front\.|\.))?([\w\-]+\.[\w\-]+)$/;
const match = window.location.hostname.match(locationRegexp) || [];
const [DOMAIN, NAME, BRANCH_NAME] = match.reverse();
const isRelease = () => BRANCH_NAME.indexOf('release') !== -1;

env.CITIBOX_API_PROTOCOL = 'https:';
if (DOMAIN) {
  if (BRANCH_NAME && !isRelease()) {
    env.CITIBOX_API_HOST = `${BRANCH_NAME}.api.${DOMAIN}`;
    env.CITIBOX_AUTH_URL = `https://${BRANCH_NAME}-dot-accounts.front.${DOMAIN}`;
  } else {
    env.CITIBOX_API_HOST = `api.${DOMAIN}`;
    env.CITIBOX_AUTH_URL = `https://accounts.${DOMAIN}`;
  }
}

env.DOMAIN = DOMAIN;
env.NAME = NAME;
env.BRANCH_NAME = BRANCH_NAME;
env.NODE_ENV = DOMAIN && DOMAIN.match(/citibox.(com|app)/) ? 'production' : 'development';

env.CITIBOX_API_PROTOCOL = 'https';
env.CITIBOX_API_HOST = 'api.citiboxlab.com';
env.CITIBOX_CLIENT_ID = 'OlolMMrqX2vc4jbltJLEHNRcXsQCjmTRC3IOAy7A';
env.COOKIEBOT_ID = '5d230b48-d812-4dbc-aa64-7f7bb057808d';
env.GOOGLE_ANALYTICS_PROPERTY_ID = 'UA-121440426-6';
env.ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.citibox.pro';
env.SENTRY_DSN = 'https://272415d7e4074735b84fc7c4f07b5377@o378284.ingest.sentry.io/5201442';
env.APP_NAME = 'pwa-couriers-deeplink';
env.APP_DEFAULT_LANG = 'es';
env.APP_SUPPORTED_LANGS = 'es';
env.I18N_PROVIDER = 'phrase';
env.LOCALE_PATH = 'assets/locales';
env.PREFIX_COUNTRIES = 'es';
env.APP_NAME = 'pwa-couriers-deeplink';
env.APP_VERSION = '5.6.0';
window.process = { env };
export { env };